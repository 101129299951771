<template>
	<ion-page id="kursmodul">
		<ion-header mode="ios">
			<ion-toolbar mode="ios">
				<ion-buttons slot="end">
					<ion-button @click="() => $router.replace(kursLink)">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="closeOutline"
							size="large"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content :key="$route.params.module">
			<TextMod
				:module-content="moduleContent"
				:featured-image="image"
				:advertorial="!onboarding"
				v-if="moduleType === 'modtext'"
			/>
			<ImgText
				:module-content="moduleContent"
				:advertorial="!onboarding"
				v-if="moduleType === 'imgtext'"
			/>
			<janein
				:module-content="moduleContent"
				v-if="moduleType === 'janein'"
				ref="janein"
			/>
			<janeinReason
				:module-content="moduleContent"
				v-if="moduleType === 'janeinreason'"
				ref="janeinreason"
			/>
			<multiple
				:module-content="moduleContent"
				v-if="moduleType === 'multiple'"
				ref="multiple"
			/>
			<answers
				:module-content="moduleContent"
				v-if="moduleType === 'answers'"
				ref="answers"
			/>
			<statement
				:module-content="moduleContent"
				v-if="moduleType === 'statement'"
				ref="statement"
			/>
			<images
				:module-content="moduleContent"
				v-if="moduleType === 'images'"
				ref="images"
			/>
		</ion-content>

		<ResultModal
			:message="`Das hat leider nicht gestimmt! ${correctAnswers()}`"
			:name="$store.state.user.first_name"
			:reasonAnswers="reasonAnswers()"
			:correctAnswers="correctAnswers()"
			:type="toastType"
			v-show="showModal"
			@onOK="navToNext()"
		/>

		<div class="bottom-bar">
			<div class="progressbar ion-padding-horizontal">
				<div class="">
					Noch
					<span :key="questionsLeft">{{ questionsLeft }}</span>
					Fragen
				</div>
				<IonProgressBar
					:color="'medium'"
					:value="status"
				></IonProgressBar>
			</div>
			<div class="buttons">
				<ion-button color="success" @click="triggerNext()"
					>Weiter ></ion-button
				>
			</div>
		</div>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonPage,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	modalController,
	IonProgressBar,
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

// Modules
import TextMod from '@/components/modules/Text.vue';
import ImgText from '@/components/modules/ImgText.vue';
import Janein from '@/components/modules/Janein.vue';
import JaneinReason from '@/components/modules/JaneinReason.vue';
import Multiple from '@/components/modules/Multiple.vue';
import Answers from '@/components/modules/Answers.vue';
import Statement from '@/components/modules/Statement.vue';
import Images from '@/components/modules/Images.vue';

// Modals
import ResultModal from '@/components/modules/ResultModal.vue';
import SecondTry from '@/components/modules/SecondTry.vue';
import SuccessKapitel from '@/components/modules/SuccessKapitel.vue';
import SuccessKurs from '@/components/modules/SuccessKurs.vue';

import Badge from '@/components/modules/Badge.vue';

export default {
	name: 'KursModul',
	components: {
		IonContent,
		IonHeader,
		IonPage,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
		TextMod,
		ImgText,
		Janein,
		JaneinReason,
		Multiple,
		Answers,
		Statement,
		Images,
		ResultModal,
		IonProgressBar,
	},
	data() {
		return {
			kapitel: {},
			image: '',
			kursname: '',
			kursicon: '',
			secondRound: false,
			counter: 0,
			modulesLength: 0,
			kapitelId: 0,
			kursId: 0,
			kapitelInKurs: 0,
			toastType: 'success',
			showModal: false,
			onboarding: false,
		};
	},
	computed: {
		isRedo() {
			if (this.userProgress.doneModules) return true;
			return false;
		},
		status() {
			return (
				(this.counter + this.userProgress.doneModules) /
				this.modulesLength
			);
		},
		questionsLeft() {
			let length = this.modulesLength;
			if (this.isRedo) length = length - this.userProgress.doneModules;
			let qleft = length - this.counter;
			return qleft;
		},
		moduleType() {
			if (!this.module || !this.module.type) return '';
			return this.module.type.type;
		},
		moduleId() {
			return parseInt(this.$route.params.module);
		},
		moduleStep() {
			if (!this.kapitel.module) return 0;
			const index = this.kapitel.module.findIndex(
				(elem) => elem.id === parseInt(this.$route.params.module)
			);
			return index + 1;
		},
		moduleContent() {
			if (!this.module || !this.module.type) return '';
			if (!this.module.type) return {};
			return this.module.type[this.moduleType];
		},
		nextModule() {
			if (!this.kapitel.module) return 1;

			// Return same module if only one question left
			// and left question is the same as current
			if (
				this.questionsLeft === 1 &&
				this.modulesLength === this.userProgress.doneModules + 1 &&
				!this.userProgress.modules[this.moduleId]
			) {
				return `/advertorial/${this.$route.params.kurs}/${this.$slugify(
					this.kursname
				)}/kapitel/${this.$route.params.kapitel}/modul/${
					this.moduleId
				}`;
			}

			// Get Index of next module in array
			let nextModIndex =
				this.kapitel.module.findIndex(
					(mod) => mod.id === this.moduleId
				) + 1;

			// Reset index to beginning of array if we are over length
			if (nextModIndex >= this.modulesLength) nextModIndex = 0;

			//  Get next module ID based on index
			let next = this.kapitel.module[nextModIndex].id;

			// If next module was done look for next undone module in array
			// Make sure next module is not current module unless it is the last module available
			if (this.userProgress.modules[next] && this.questionsLeft > 0) {
				for (let i = 0; i < this.modulesLength; i++) {
					if (i !== nextModIndex && i !== nextModIndex - 1) {
						next = this.kapitel.module[i].id;
						if (!this.userProgress.modules[next]) break;
					}
				}
			}

			return `/advertorial/${this.$route.params.kurs}/${this.$slugify(
				this.kursname
			)}/kapitel/${this.$route.params.kapitel}/modul/${next}`;
		},
		previousModule() {
			if (!this.kapitel.module) return 0;
			let prevModIndex =
				this.kapitel.module.findIndex(
					(mod) => mod.id === this.moduleId
				) - 1;
			if (prevModIndex < 0) return null;
			return this.kapitel.module[prevModIndex].id;
		},
		module() {
			if (!this.kapitel.module) return {};
			return this.kapitel.module.find((mod) => mod.id === this.moduleId);
		},
		userProgress() {
			if ((this.kursId === 0) | (this.kapitelId === 0)) return {};
			return (
				this.$store.state.user.progress[this.kursId].kapitel[
					this.kapitelId
				] || {
					done: false,
					doneModules: 0,
					modules: {},
				}
			);
		},
		kursLink() {
			return `/kurs/${this.kursId}/${this.$slugify(this.kursname)}`;
		},
	},
	methods: {
		async ionViewWillEnter() {
			await this.fetchData();

			// Check if Kapitel is done and reset progress if it is
			this.resetKapitel();

			// filter out done modules and store queue
			this.storeToDoModules();
		},
		async fetchData() {
			// Fetch Data
			const kapitelCall = this.$http.get(
				`/items/kapitel/${this.$route.params.kapitel}?fields=*,module.*`
			);
			const kursCall = this.$http.get(
				`/items/kurse/${this.$route.params.kurs}?fields=featured_image,name,icon,kapitel.*,onboarding`
			);

			const [kapitelData, kursData] = await Promise.all([
				kapitelCall,
				kursCall,
			]);

			// Set Data
			this.image = kursData.data.data.featured_image;
			this.kursname = kursData.data.data.name;
			this.kursicon = kursData.data.data.icon;
			this.kapitelInKurs = kursData.data.data.kapitel;
			this.onboarding = kursData.data.data.onboarding;
			this.kapitel = kapitelData.data.data;
			this.kapitel.module.sort((a, b) => a.sort - b.sort);

			this.modulesLength = this.kapitel.module.length;
			this.kursId = parseInt(this.$route.params.kurs);
			this.kapitelId = parseInt(this.$route.params.kapitel);

			return true;
		},
		resetKapitel() {
			if (
				this.$store.state.user.progress[this.kursId]?.kapitel[
					this.kapitelId
				]?.done
			) {
				this.$store.state.user.progress[this.kursId].kapitel[
					this.kapitelId
				] = {
					done: false,
					doneModules: 0,
					modules: {},
				};
			}
		},
		storeToDoModules() {
			let modules = this.kapitel.module;

			// Filter done modules from queue
			if (
				this.$store.state.user.progress[this.kursId]?.kapitel[
					this.kapitelId
				]?.doneModules
			) {
				const doneModules =
					this.$store.state.user.progress[this.kursId].kapitel[
						this.kapitelId
					].modules;
				modules = modules.filter((mod) => !doneModules[mod.id]);
			}

			// Filter wrong modules from queue
			if (this.$store.state.redoModules.length)
				modules = modules.filter(
					(mod) =>
						!this.$store.state.redoModules.find(
							(mod2) => mod.id === mod2.id
						)
				);

			// store queue for this kapitel
			this.$store.dispatch('setCurrentModules', modules);
		},
		reasonAnswers() {
			// For JaNein Reason we show explaination
			if (this.moduleType === 'janeinreason')
				return (
					'<br/><br/><strong>Erklärung:</strong><br/>' +
					this.moduleContent.reason
				);
			return '';
		},
		correctAnswers() {
			// Get Correct answer for Toast Notification
			// For JaNeinReason use Reason instead
			let answers = this.moduleContent.correct;
			let value = '<br/><br/><strong>Richtige Antworten: </strong><br/>';
			if (this.moduleType === 'statement')
				return (
					'<br/><br/><strong>Richtige Antwort:</strong><br/>' +
					this.moduleContent[answers]
				);
			else if (this.moduleType === 'janeinreason')
				return this.reasonAnswers();
			else if (typeof answers === 'string')
				return (
					'<br/><br/><strong>Richtige Antwort:</strong> <br/>- ' +
					answers
				);
			else if ((this.moduleType === 'images') & Array.isArray(answers)) {
				answers.forEach(
					(ans) =>
						(value += `<img src="${this.imgLink(
							ans
						)}" width="100" height="100" style="padding: 4px;"/>`)
				);
				return value;
			} else if (Array.isArray(answers)) {
				answers.forEach((ans) => (value += '- ' + ans + '<br/>'));
				return value;
			}
		},
		imgLink(img) {
			if (!img) return;

			return `${
				this.$store.state.imageBase
			}${img}?key=images-sq&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
		updateProgress(done = false, fail = false) {
			// get the user's progress or set it to an empty object
			let progress = this.$store.state.user.progress || {};

			// get the current chapter's progress or set it to an empty object with 0 completed modules
			let kapitel = progress[this.kursId]?.kapitel[this.kapitelId] || {
				modules: {},
				doneModules: 0,
			};

			// check if the chapter is completed and hasn't been marked as completed yet
			if (done && !kapitel.done) {
				// increment the number of completed chapters, ensuring it doesn't exceed the total number of chapters
				progress[this.kursId].doneKapitel = Math.min(
					progress[this.kursId].doneKapitel + 1,
					this.kapitelInKurs.length
				);

				// mark the chapter as completed
				kapitel.done = true;

				// increment the number of completed modules, ensuring it doesn't exceed the total number of modules
				kapitel.doneModules = Math.min(
					kapitel.doneModules + 1,
					this.modulesLength
				);

				// mark the current module as completed
				kapitel.modules[this.moduleId] = true;

				// if all chapters are completed, mark the course as completed
				progress[this.kursId].done =
					progress[this.kursId].doneKapitel ===
					this.kapitelInKurs.length;
			} else if (!fail && !kapitel.modules[this.moduleId]) {
				// if the module hasn't been completed, increment the number of completed modules
				kapitel.doneModules++;
				// mark the current module as completed
				kapitel.modules[this.moduleId] = true;
			} else if (!fail) {
				// if the module has already been completed, return false
				return false;
			}
			progress[this.kursId].kapitel[this.kapitelId] = kapitel;
			// update the user's progress in the store
			this.$store.dispatch('updateUserProgress', { progress });
		},
		moduleDone(key) {
			return key in this.userProgress.modules;
		},
		async triggerNext() {
			// remove module from queue
			this.$store.dispatch('removeCurrentModule', this.moduleId);

			// If we have a text module save progress and move on
			if (
				this.moduleType === 'modtext' ||
				this.moduleType === 'imgtext'
			) {
				this.updateProgress();
				return this.navToNext();
			}

			// Validate module answer
			const thisModule = this.$refs[this.moduleType];
			const validated = await thisModule.validate();

			// Handle result
			// success: save progress and show success
			// failure: show toast & add module to redo queue
			this.showModal = true;
			if (validated) {
				this.updateProgress();
				this.toastType = 'success';
			} else {
				this.updateProgress(false, true);
				this.$store.dispatch('addRedoModule', this.module);
				this.toastType = 'failure';
			}
			
			if (
				!this.$store.state.redoModules.length &&
				!this.$store.state.currentModules.length
			) {
				this.navToNext();
			}
		},
		navToNext() {
			// Reset Toasts
			this.showModal = false;

			// Show success if all modules are done
			// Else show secondTry if we are on last module
			// Else move to next module
			if (
				this.$store.state.redoModules.length &&
				!this.$store.state.currentModules.length
			)
				this.openSecondTryModal();
			else if (
				!this.$store.state.redoModules.length &&
				!this.$store.state.currentModules.length
			)
				this.openSuccessModal();
			else this.$router.push({ path: this.nextModule });
		},
		async openSecondTryModal() {
			const modal = await modalController.create({
				component: SecondTry,
			});
			modal
				.onDidDismiss()
				.then((data) => this.secondTimeOrAbort(data.data));
			return await modal.present();
		},
		async openSuccessModal() {
			let progress = this.$store.state.user.progress;

			// update user progress
			this.updateProgress(true);

			let modalConfig = {
				component: SuccessKapitel,
				backdropDismiss: false,
				componentProps: {
					name: this.kapitel.name,
				},
			};
			if (progress[this.kursId].done) {
				modalConfig = {
					component: SuccessKurs,
					backdropDismiss: false,
					componentProps: {
						name: this.kursname,
					},
				};
			}

			const modal = await modalController.create(modalConfig);
			modal.onDidDismiss().then((data) => {
				if (this.$store.state.user.progress[this.kursId].done)
					this.openBadgeModal();
				else this.nextKapitelorAbort(data.data);
			});
			return await modal.present();
		},
		getProgress() {
			let progress = this.$store.state.user.progress || {};

			if (
				!progress[this.kursId].kapitel[this.kapitelId] ||
				!progress[this.kursId].kapitel[this.kapitelId].modules[
					this.moduleId
				]
			) {
				progress[this.kursId].kapitel[this.kapitelId] =
					this.userProgress;
				++progress[this.kursId].kapitel[this.kapitelId].doneModules;
				progress[this.kursId].kapitel[this.kapitelId].modules[
					this.moduleId
				] = true;
				return progress;
			} else return false;
		},
		async openBadgeModal() {
			let badges = this.$store.state.user.badges || [];
			let already = badges.find((badge) => badge.icon === this.kursicon);
			if (!already)
				badges.push({ name: this.kursname, icon: this.kursicon });
			this.$store.dispatch('updateUserProgress', { badges });

			const modal = await modalController.create({
				component: Badge,
				componentProps: { name: this.kursname, icon: this.kursicon },
			});
			modal.onDidDismiss().then(() => this.secondTimeOrAbort());
			return await modal.present();
		},
		async secondTimeOrAbort(kill) {
			if (kill){
				return this.$router.replace({
					path: this.kursLink,
				});
			}

			// Do 2nd time
			// reset the modules queue with the redo queue
			this.$store.dispatch('resetModules', this.$store.state.redoModules);
			this.$router.push({ path: this.nextModule });

			// Fallback: make sure we reload and state is reset if is same module
			if (this.nextModule === this.$route.fullPath) {
				this.$store.dispatch('updateLoading', true);
				this.kapitel.module = null;
				await this.fetchData();
				this.$store.dispatch('updateLoading', false);
			}
		},
		async nextKapitelorAbort(kill) {
			if (kill)
				return this.$router.push({
					path: this.kursLink,
				});

			this.$store.dispatch('updateLoading', true);
			let nextIndex =
				this.kapitelInKurs.findIndex(
					(mod) => mod.id === this.kapitelId
				) + 1;
			if (nextIndex >= this.kapitelInKurs.length) nextIndex = 0;
			await this.$router.push({
				path: `${this.kursLink}/kapitel/${this.kapitelInKurs[nextIndex].id}/modul/${this.kapitelInKurs[nextIndex].module[0]}`,
			});
			await this.fetchData();
			this.storeToDoModules();
			this.$store.dispatch('updateLoading', false);
		},
	},
	setup() {
		return { closeOutline };
	},
};
</script>



<style lang="scss" scoped>
#kursmodul {
	ion-header {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	ion-toolbar {
		--border-style: none;
		--background: transparent;
	}
	ion-content {
		--offset-top: 0px;
	}
	ion-content::part(background) {
		background-color: white;
	}

	ion-content::part(scroll) {
		margin-bottom: 85px;
	}

	.frage {
		span {
			color: var(--ion-color-dark);
			font-size: 12px;
		}
		padding-top: 60px;
		border-bottom: 1px solid var(--ion-color-light);
	}

	ion-backdrop {
		z-index: 110;
		--ion-backdrop-color: black;
		opacity: 0.1;
	}

	.toast-cancel {
		border: 1px solid white;
	}

	.bottom-bar {
		background-color: white;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 85px;
		z-index: 100;

		IonProgressBar {
			width: 100%;
			margin-bottom: 2rem;
		}

		.buttons {
			display: flex;
			justify-content: flex-end;
			flex-wrap: wrap;
			padding: 0.1rem 1rem 1rem 1rem;
			ion-button::part(native) {
				padding: 0 2rem;
			}

			div {
				flex: 1;
				color: black;
				display: flex;
				align-items: center;
			}
		}

		.progressbar {
			display: flex;
			align-items: center;
			color: var(--ion-color-dark);

			div {
				display: flex;
				align-items: center;
				margin-right: 10px;
			}

			span {
				color: var(--ion-color-success);
				font-weight: bold;
				font-size: 20px;
				margin: 0 5px;
			}

			hr {
				border-bottom: 1px solid var(--ion-color-dark);
				flex-grow: 4;
				margin-left: 10px;
			}
		}
	}
}
</style>
